import React from 'react'
import { Link } from "gatsby"
import { Helmet } from 'react-helmet'
import Slider from 'react-slick'
import { RichText } from 'prismic-reactjs'
import { graphql } from 'gatsby'
import { siteMetadata } from '../../gatsby-config'
import styled from 'styled-components'

import { linkResolver } from '../utils/linkResolver'

import Layout from '../components/layouts'
import Meta from '../components/meta'
import GoogleMap from '../components/maps'

import './entdeckung.scss'

export const query = graphql`
query EntdeckungenQuery($uid: String) {
  prismic {
    allEntdeckungens(uid: $uid) {
      edges {
        node {
          _meta {
            uid
            id
          }
          detail_text
          maximale_teilnehmer
          ort
          ort_der_entdeckung_lang
          ort_detailiert
          link_google_maps {
            ... on PRISMIC__ExternalLink {
              url
            }
          }
          bild_text
          vorschau_bild
          detail_bilder {
            detail_bild
          }
          termine {
            termin
          }
          titel
          preis
        }
      }
    }
  }
}
`

const Image = styled.div`
width: 100%;
min-height:400px;
background: url("${props => props.url}") no-repeat center;
background-size: cover;
`

const ImageDots = styled.div`
width: 100%;
height: 100%;
background: url("${props => props.url}") no-repeat center;
background-size: cover;
`

const Entdeckung = props => {
  const { location } = props

  const doc = props.data.prismic.allEntdeckungens.edges.slice(0, 1).pop()
  if (!doc) return null

  if (!doc.node.preis || !doc.node.detail_bilder[0].detail_bild) {
    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Fehler" />
        <div className="container-fluid container-grey p-5 text-center"><h3>Produkt kann nicht angezeigt werden, weil es keinen Preis hat</h3></div>
      </Layout>
    )
  }

  let detail_bilder = []
  let detail_bilder_dots = []

  doc.node.detail_bilder.map((value, index) => {

    detail_bilder.push(<Image url={value.detail_bild.url}></Image>)
    detail_bilder_dots.push(<ImageDots url={value.detail_bild.url}></ImageDots>)
  })

  const settings = {
    customPaging: function(i) {
      return <a>{detail_bilder_dots[i]}</a>
    },
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    dotsClass: 'entdeckung-slick-dots',
  }

  let dataIndex = -1

  const Slide = () => {
    dataIndex += 1
      return (
        <div data-index={dataIndex} key={dataIndex}>
          {detail_bilder[dataIndex]}
        </div>
      )
   }


  return (
<Layout location={location}>
      <Meta site={siteMetadata} title={RichText.asText(doc.node.titel)} />
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />       
      </Helmet>
      <div className="container-grey">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 text-center">
              <h3 className="font-weight-bold mt-5">
                {RichText.asText(doc.node.titel)}
              </h3>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 col-md-7">
              <Slider {...settings}>{detail_bilder.map(e => Slide())}</Slider>
            </div>
            <div className="col-12 col-md-4 pb-5">
              <div className="entdeckung-detail-rechter-text">
                {RichText.render(doc.node.bild_text, linkResolver)}
              </div>
              <div className="info-newsletter">
                Aufgrund der aktuellen Situation können wir leider noch keine konkreten Termine anbieten. 
                Wir informieren dich gerne, sobald eine Durchführung möglich ist.
                Melde dich für unseren Newsletter an, um auf dem Laufenden zu bleiben, oder schreib uns ein E-Mail, 
                wenn du genau zu dieser Veranstaltung Informationen erhalten möchtest.
                <br />
                <br />
                <Link to="/page/newsletter/" className="btn btn-lg btn-besonder-red">
                  Zum Newsletter ...
                </Link>
              </div>
            </div>
            <div className="col-md-12 entdeckung-detail-text pb-5 pt-2">
              {RichText.render(doc.node.detail_text, linkResolver)}
            </div>
          </div>
        </div>        
      </div>

      <div className="container-red">
        <div className="container">
          <div className="row pt-5 pb-5">
            <div className="col-md-12 text-center">
              <h4 className="text-light mb-5">
                {doc.node.ort_der_entdeckung_lang
                  ? RichText.asText(doc.node.ort_der_entdeckung_lang)
                  : ''}
              </h4>
              <GoogleMap
                lat={doc.node.ort_detailiert.latitude}
                lng={doc.node.ort_detailiert.longitude}
                link={doc.node.link_google_maps}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Entdeckung;