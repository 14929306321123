import React from 'react'
import uniqueId from 'lodash/uniqueId'

import Anchor from './elements/anchor'
import Button from './elements/button'
import ButtonMore from './elements/buttonMore'
import ButtonAnchor from './elements/buttonAnchor'
import Text from './elements/text'
import Image from './elements/image'

const renderFieldsToColumn = (column, uid, color, fields) => {
  return fields.map((field, index) => {
    if (field.spalte_1_oder_2_oder_3_oder_4 === column) {
      switch (field.render) {
        case 'Text': return (
          <Text
            key={uniqueId(uid + '-')}
            text={field.text}
            color={color}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            textCenter={false}
            specialClass={field.spezial_klasse} />
        )
        case 'Bild': return (
          <Image
            key={uniqueId(uid + '-')}
            image={field.bild}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            specialClass={field.spezial_klasse} />
        )
        case 'Button Link': return (
          <Button
            key={uniqueId(uid + '-')}
            buttonLink={field.button_link}
            buttonColor={field.button_color}
            buttonText={field.button_text}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            specialClass={field.spezial_klasse} />
        )
        case 'Titel': return (
          <Text
            key={uniqueId(uid + '-')}
            text={field.titel_slice}
            color={color}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            textCenter={false}
            specialClass={field.spezial_klasse} />
        )
        case 'Button Mehr Anzeigen': return (
          <ButtonMore
            key={uniqueId(uid + '-')}
            buttonColor={field.button_color}
            buttonText={field.button_text}
            moreText={field.text_mehr_anzeigen}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            textCenter={true}
            specialClass={field.spezial_klasse} />
        )
        case 'Button Anchor': return (
          <ButtonAnchor
            key={uniqueId(uid + '-')}
            buttonColor={field.button_color}
            buttonText={field.button_text}
            anchor={field.anchor}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}                
            specialClass={field.spezial_klasse} />
        )
        case 'Anchor': return (
          <Anchor
            key={uniqueId(uid + '-')}
            anchor={field.anchor} />
        )     
        default: return null
        }
      }
    return null
    })
}

const renderFieldsToColumnMobile = (column, uid, color, fields) => {
  return fields.map((field, index) => {
    if (field.spalte_1_oder_2_oder_3_oder_4 === column) {
      switch (field.render) {
        case 'Text': return (
          <Text
            key={uniqueId(uid + '-')}
            text={field.text}
            color={color}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            textCenter={true}
            specialClass={field.spezial_klasse} />
        )
        case 'Bild': return (
          <Image
            key={uniqueId(uid + '-')}
            image={field.bild}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            specialClass={field.spezial_klasse} />
        )
        case 'Button Link': return (
          <Button
            key={uniqueId(uid + '-')}
            buttonLink={field.button_link}
            buttonColor={field.button_color}
            buttonText={field.button_text}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            specialClass={field.spezial_klasse} />
        )
        case 'Titel': return (
          <Text
            key={uniqueId(uid + '-')}
            text={field.titel_slice}
            color={color}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            textCenter={true}
            specialClass={field.spezial_klasse} />
        )
        case 'Button Mehr Anzeigen': return (
          <ButtonMore
            key={uniqueId(uid + '-')}
            buttonColor={field.button_color}
            buttonText={field.button_text}
            moreText={field.text_mehr_anzeigen}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            textCenter={true}
            specialClass={field.spezial_klasse} />
        )
        case 'Button Anchor': return (
          <ButtonAnchor
            key={uniqueId(uid + '-')}
            buttonColor={field.button_color}
            buttonText={field.button_text}
            anchor={field.anchor}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}                
            specialClass={field.spezial_klasse} />
        )
        case 'Anchor': return (
          <Anchor
            key={uniqueId(uid + '-')}
            anchor={field.anchor} />
        )     
        default: return null
        }
      }
    return null
    })
}

const FourContentBlock = (props) => {
  const { uid } = props;
  const { primary, fields } = props.content;
  const {
    spalte_1_breite_desktop: columnOneWidthDesktop,
    spalte_2_breite_desktop: columnTwoWidthDesktop,
    spalte_3_breite_desktop: columnThreeWidthDesktop,
    spalte_4_breite_desktop: columnFourWidthDesktop,
    spalte_1_breite_mobil: columnOneWidthMobil,
    spalte_2_breite_mobil: columnTwoWidthMobil,
    spalte_3_breite_mobil: columnThreeWidthMobil,
    spalte_3_breite_mobil: columnFourWidthMobil,
    content_block_color: color,
    abstand_rand: padding } = primary;

  return (
    <div className={`container-fluid container-${color} justify-content-center align-items-center p-${padding}`}>
      <div className={`row p-3 justify-content-center d-none d-lg-flex`}>
        <div className={`col-${columnOneWidthMobil} col-lg-${columnOneWidthDesktop} d-none d-lg-block`}>
          {renderFieldsToColumn('1', uid, color, fields)}
        </div>
        <div className={`col-${columnTwoWidthMobil} col-lg-${columnTwoWidthDesktop} d-none d-lg-block`}>
          { renderFieldsToColumn('2', uid, color, fields) }
        </div>
        <div className={`col-${columnThreeWidthMobil} col-lg-${columnThreeWidthDesktop} d-none d-lg-block`}>
          { renderFieldsToColumn('3', uid, color, fields) }
        </div> 
        <div className={`col-${columnFourWidthMobil} col-lg-${columnFourWidthDesktop} d-none d-lg-block`}>
          { renderFieldsToColumn('4', uid, color, fields) }
        </div> 
      </div>
  <div className={`row p-3 justify-content-center d-lg-none`}>
        <div className={`col-${columnOneWidthMobil} col-lg-${columnOneWidthDesktop} d-lg-none pb-3`}>
          {renderFieldsToColumnMobile('1', uid, color, fields)}
        </div>
        <div className={`col-${columnTwoWidthMobil} col-lg-${columnTwoWidthDesktop} d-lg-none pb-3`}>
          { renderFieldsToColumnMobile('2', uid, color, fields) }
        </div>
        <div className={`col-${columnThreeWidthMobil} col-lg-${columnThreeWidthDesktop} d-lg-none`}>
          { renderFieldsToColumnMobile('3', uid, color, fields) }
        </div> 
        <div className={`col-${columnFourWidthMobil} col-lg-${columnFourWidthDesktop} d-lg-none`}>
          { renderFieldsToColumnMobile('4', uid, color, fields) }
        </div> 
      </div>
    </div>
  ) 
}

export default FourContentBlock;