const { createProxyMiddleware } = require('http-proxy-middleware')

module.exports = {
  siteMetadata: {
    title: `beSonder`,
    description: `beSonder - Lass dich inspirieren. Erwecke deinen Entdeckergeist.`,
    author: `@belech`,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-prismic-graphql`,
      options: {
        repositoryName: 'besonder',
        path: '/preview',
        previews: true,
        pages: [
          {
            type: 'Page',
            match: '/page/:uid',
            path: '/page-preview',
            component: require.resolve('./src/templates/page.js')
          },
          {
            type: 'Produkte',
            match: '/produkt/:uid',
            path: '/produkt-preview',
            component: require.resolve('./src/templates/produkt.js')
          },
          {
            type: 'Entdeckungen',
            match: '/entdeckung/:uid',
            path: '/entdeckung-preview',
            component: require.resolve('./src/templates/entdeckung.js')
          },           
        ]
      }
    },
    `gatsby-plugin-sass`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        display: `minimal-ui`,
        icon: `src/images/favicon.png`, // This path is relative to the root of the site.
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    {
      resolve: 'gatsby-plugin-html-attributes',
      options: {
        lang: 'de'
      }
    },
    {
      resolve: `gatsby-plugin-gdpr-cookies`,
      options: {
        googleAnalytics: {
          trackingId: 'UA-166252586-1', // leave empty if you want to disable the tracker
          cookieName: 'gatsby-gdpr-google-analytics', // default
          anonymize: true // default
        },
        // defines the environments where the tracking should be available  - default is ["production"]
        environments: ['production', 'development']
      },
    },
    `gatsby-plugin-styled-components`
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // 'gatsby-plugin-offline',
  ],
  developMiddleware: app => {
    app.use(
      '/.netlify/functions/',
      createProxyMiddleware({
        target: 'http://localhost:9000',
        secure: false, // Do not reject self-signed certificates.
        pathRewrite: {
          '/.netlify/functions/': '',
        },
      })
    )
  },
}
