
import React from 'react'
import { RichText } from 'prismic-reactjs'

const ButtonAnchor = ({ anchor, buttonColor, buttonText, sizeDesktop, sizeMobile, textCenter, specialClass }) => {
  if (!buttonText || !anchor) return null;
  
  let newClass = ''
  if (specialClass) newClass = RichText.asText(specialClass)
  if (textCenter) newClass += ' text-center'

  return (
    <div className={`row p-2 justify-content-center align-items-center text-center ${newClass}`}>
      <div className={`col-${sizeMobile} col-lg-${sizeDesktop}`}>
        <a className={`btn btn-lg btn-besonder-${buttonColor}`} href={`#${RichText.asText(anchor)}`}>
         {RichText.asText(buttonText)}
         </a>
      </div>
    </div>
  );       
}

export default ButtonAnchor;