import React from 'react'
import uniqueId from 'lodash/uniqueId'

import Anchor from './elements/anchor'
import Button from './elements/button'
import ButtonMore from './elements/buttonMore'
import ButtonAnchor from './elements/buttonAnchor'
import Text from './elements/text'
import Image from './elements/image'

const renderFieldsToColumn = (column, uid, color, fields) => {
  return fields.map((field, index) => {
    if (field.spalte_1_oder_2 === column) {
      switch (field.render) {
        case 'Text': return (
          <Text
            key={uniqueId(uid + '-')}
            text={field.text}
            color={color}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            textCenter={false}
            display={`d-none d-lg-block`}
            specialClass={field.spezial_klasse} />
        )
        case 'Bild': return (
          <Image
            key={uniqueId(uid + '-')}
            image={field.bild}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            display={`d-none d-lg-block`}
            specialClass={field.spezial_klasse} />
        )
        case 'Button Link': return (
          <Button
            key={uniqueId(uid + '-')}
            buttonLink={field.button_link}
            buttonColor={field.button_color}
            buttonText={field.button_text}
            textCenter={false}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            display={`d-none d-lg-block`}
            specialClass={field.spezial_klasse} />
        )
        case 'Titel': return (
          <Text
            key={uniqueId(uid + '-')}
            text={field.titel_slice}
            color={color}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            textCenter={false}
            display={`d-none d-lg-block`}
            specialClass={field.spezial_klasse} />
        )
        case 'Button Mehr Anzeigen': return (
          <ButtonMore
            key={uniqueId(uid + '-')}
            buttonColor={field.button_color}
            buttonText={field.button_text}
            moreText={field.text_mehr_anzeigen}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}                
            specialClass={field.spezial_klasse} />
        )
        case 'Button Anchor': return (
          <ButtonAnchor
            key={uniqueId(uid + '-')}
            buttonColor={field.button_color}
            buttonText={field.button_text}
            anchor={field.anchor}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}                
            specialClass={field.spezial_klasse} />
        )
        case 'Anchor': return (
          <Anchor
            key={uniqueId(uid + '-')}
            anchor={field.anchor} />
          )
          default: return null
        }
      }
    return null
    })
}

const renderHeadingForMobile = (column, uid, color, fields) => {
  return fields.map((field, index) => {
    if (field.spalte_1_oder_2 === column) {
      switch (field.render) {
        case 'Titel': return (
          <Text
            key={uniqueId(uid + '-')}
            text={field.titel_slice}
            color={color}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            textCenter={true}
            display={`d-lg-none`}
            specialClass={field.spezial_klasse} />
        )
        default: return null
      }
    }
    return null
  })  
}

const renderImageForMobile = (column, uid, color, fields) => {
  return fields.map((field, index) => {
    if (field.spalte_1_oder_2 === column) {
      switch (field.render) {
        case 'Bild': return (
          <Image
            key={uniqueId(uid + '-')}
            image={field.bild}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            display={`d-lg-none`}
            specialClass={field.spezial_klasse} />
        )
        default: return null
      }
    }
  })  
}

const renderButtonForMobile = (column, uid, color, fields) => {
  return fields.map((field, index) => {
    if (field.spalte_1_oder_2 === column) {
      switch (field.render) {
        case 'Button Link': return (
          <Button
            key={uniqueId(uid + '-')}
            buttonLink={field.button_link}
            buttonColor={field.button_color}
            buttonText={field.button_text}
            textCenter={true}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            display={`d-lg-none`}
            specialClass={field.spezial_klasse} />
        )
        default: return null
      }
    }
  })  
}

const renderTextForMobile = (column, uid, color, fields) => {
  return fields.map((field, index) => {
    if (field.spalte_1_oder_2 === column) {
      switch (field.render) {
        case 'Text': return (
          <Text
            key={uniqueId(uid + '-')}
            text={field.text}
            color={color}
            sizeDesktop={field.size_desktop}
            sizeMobile={field.size_mobil}
            textCenter={true}
            display={`d-lg-none`}
            specialClass={field.spezial_klasse} />
        )
        default: return null
      }
    }})
}

const checkBothColumnsForImage = (fields) => {
  let columnOneImage = false
  let columnTwoImage = false

  fields.map((field, index) => {
    if (field.spalte_1_oder_2 === '1' && field.render === 'Bild') columnOneImage = true
    if (field.spalte_1_oder_2 === '2' && field.render === 'Bild') columnTwoImage = true
  })
  
  if (columnOneImage && columnTwoImage) return true
  return false
}

const TwoContentBlock = (props) => {
  const { uid } = props;
  const { primary, fields } = props.content;
  const {
    spalte_1_breite_desktop: columnOneWidthDesktop,
    spalte_2_breite_desktop: columnTwoWidthDesktop,
    spalte_1_breite_mobil: columnOneWidthMobil,
    spalte_2_breite_mobil: columnTwoWidthMobil,
    content_block_color: color, 
    abstand_rand: padding } = primary;
  
  const bothColumnsHaveImage = checkBothColumnsForImage(fields)
  if (bothColumnsHaveImage) {
    return (
      <div className={`container-fluid container-${color} justify-content-center align-items-center p-${padding}`}>
        <div className={`row p-3 justify-content-center align-items-center`}>
      
          <div className={`col-${columnOneWidthMobil} col-lg-${columnOneWidthDesktop}`}>
            {renderHeadingForMobile('1', uid, color, fields)}
            {renderImageForMobile('1', uid, color, fields)}
            {renderTextForMobile('1', uid, color, fields)}
            {renderButtonForMobile('1', uid, color, fields)}
            {renderFieldsToColumn('1', uid, color, fields)}
          </div>
          <div className={`col-${columnTwoWidthMobil} col-lg-${columnTwoWidthDesktop}`}>
            {renderHeadingForMobile('2', uid, color, fields)}
            {renderImageForMobile('2', uid, color, fields)}
            {renderTextForMobile('2', uid, color, fields)}            
            {renderButtonForMobile('2', uid, color, fields)}
            {renderFieldsToColumn('2', uid, color, fields)}
          </div>      
        </div>
      </div>
    )     
  }

  return (
    <div className={`container-fluid container-${color} justify-content-center align-items-center p-${padding}`}>
      <div className={`row p-3 justify-content-center align-items-center`}>
        {renderHeadingForMobile('1', uid, color, fields)}
        {renderHeadingForMobile('2', uid, color, fields)}
        {renderImageForMobile('1', uid, color, fields)}
        {renderImageForMobile('2', uid, color, fields)}
        {renderTextForMobile('1', uid, color, fields)}
        {renderTextForMobile('2', uid, color, fields)}
        {renderButtonForMobile('1', uid, color, fields)}
        {renderButtonForMobile('2', uid, color, fields)}
        <div className={`col-${columnOneWidthMobil} col-lg-${columnOneWidthDesktop}`}>
          {renderFieldsToColumn('1', uid, color, fields)}
        </div>
        <div className={`col-${columnTwoWidthMobil} col-lg-${columnTwoWidthDesktop}`}>
          {renderFieldsToColumn('2', uid, color, fields)}
        </div>      
      </div>
    </div>
  ) 
}

export default TwoContentBlock;