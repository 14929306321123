import React from 'react'
import { StaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { linkResolver } from '../../utils/linkResolver'
import besonderLogo from '../../images/besonder-logo.svg'
import { config } from "@fortawesome/fontawesome-svg-core";
import '@fortawesome/fontawesome-svg-core/styles.css'
import './style.scss'

config.autoAddCss = false;

const CartSummary = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;
  font-weight: bold;
`

const query = graphql`
query NaviQuery {
  prismic {
    allNavigations {
      edges {
        node {
          navigation_items {
            navigation_item {
              ... on PRISMIC_Page {
                titel
                _linkType
                _meta {
                  uid
                  type
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default props => (
  <StaticQuery
    query={query}
    render={ data => <Navi data={ data } { ...props }/> }
  />
)

const Navi = (props) => {
  const { location, data } = props

  const doc = data.prismic.allNavigations.edges.slice(0, 1).pop();

  if (!doc) return null

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light shadow fixed-top">
      <div className="container navbar-container">
        <Link to="/page/home" className="navbar-brand">
          <img
            alt=""
            className="besonder-logo ml-3 ml-md-0"
            src= { besonderLogo }
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ml-auto text-center">
            {doc.node.navigation_items.map((data, index) => {
              return (
                  <li key={`navi-${index}`} className={location.pathname === `/page/${data.navigation_item._meta.uid}/` ? 'nav-item active' : 'nav-item'}>
                    <Link to={linkResolver(data.navigation_item._meta)} className="nav-link">
                      {RichText.asText(data.navigation_item.titel)}
                    </Link>
                  </li>
                )
              })
            }
            <li>
              <CartSummary className="snipcart-summary snipcart-center nav-icons">
                <a
                  href="/#"
                  className="snipcart-checkout nav-link nav-link-icon"
                  data-toggle="collapse"
                  data-target="#navbarResponsive"
                >
                  <FontAwesomeIcon
                    icon={faShoppingCart}
                    className="fa-lg"
                  />
                </a>
                <a
                  href="https://www.instagram.com/besonder_official/"
                  className="nav-link nav-link-icon nav-link-instagram"
                >
                  <FontAwesomeIcon
                    icon={faInstagramSquare}
                    className="fa-lg"
                  />
                </a>

                <a
                  href="https://www.facebook.com/BeSonder-108834997490098"
                  className="nav-link nav-link-icon nav-link-facebook"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className="fa-lg"
                  />
                </a>
              </CartSummary>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}