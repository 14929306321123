import React from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from "gatsby"

import './newsletter.scss'

const Newsletter = ({ content }) => {

  return (
    <div className="container-grey">
      <Helmet>
        <link
          rel="stylesheet"
          href="https://sibforms.com/forms/end-form/build/sib-styles.css"
        />
        <script src={withPrefix('sib.js')} />
        <script src={withPrefix('newsletter.js')} />
        <script src="https://www.google.com/recaptcha/api.js?hl=de"></script>
        </Helmet>      
        <div className="container p-3">
          <div className="row p-2">
            <div className="col-12 text-center">
              <h3 className="font-weight-bold">Newsletter-Anmeldung</h3>
              <div className="sib-form">
                <div id="sib-form-container" className="sib-form-container">
                  <div id="error-message" className="sib-form-message-panel">
                    <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="fa-lg mr-2"
                      />
                      <span className="sib-form-message-panel__inner-text">
                        Die Anmeldung konnte nicht gespeichert werden. Bitte
                        versuche es erneut.
                      </span>
                    </div>
                  </div>
                  <div></div>
                  <div
                    id="success-message"
                    className="sib-form-message-panel"
                  >
                    <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="fa-lg mr-2"
                      />
                      <span className="sib-form-message-panel__inner-text">
                        Die Anmeldung war erfolgreich.
                      </span>
                    </div>
                  </div>
                  <div></div>
                  <div
                    id="sib-container"
                    className="sib-container--large sib-container--vertical"
                  >
                    <form
                      id="sib-form"
                      method="POST"
                      action="https://d56463dd.sibforms.com/serve/MUIEAA2bqPPleRQxWiYyHhkHHUeqp3Sr5L7zuI3MzRja_YXJ9f34aYIMyPkn6knOcASxH2yj6XEWEB7anb14N3-9BfrT1KtsB-39jDEqkziTZlHJX2M0s_X411X72ocUO4pIhlF1BbXnlopY8Q_Rc1cAnkRfnW-klhtCWQC-6kk-Rfa2vW2Oem3Z-pkTsk_5UCMUrLWG-KkrW6C9"
                      data-type="subscription"
                    >
                      <div>
                        <div className="sib-form-block">
                          <div className="sib-text-form-block">
                            <p>
                              Melde dich mit deiner E-Mail Adresse zu unserem Newsletter an, um auf dem Laufenden zu bleiben.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="sib-input sib-form-block">
                          <div className="form__entry entry_block">
                            <div className="form__label-row ">
                              <label
                                className="entry__label"
                                htmlFor="EMAIL"
                                data-required="*"
                              >
                                Gib bitte deine E-Mail-Adresse für die Anmeldung an, z. B. abc@xyz.com.
                              </label>

                              <div className="entry__field">
                                <input
                                  className="input"
                                  type="text"
                                  id="EMAIL"
                                  name="EMAIL"
                                  autoComplete="off"
                                  placeholder="EMAIL"
                                  data-required="true"
                                  required
                                />
                              </div>
                            </div>

                            <label htmlFor="EMAIL" className="entry__error entry__error--primary"></label>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div
                          className="sib-optin sib-form-block"
                          data-required="true"
                        >
                          <div className="form__entry entry_mcq">
                            <div className="form__label-row ">
                              <div className="entry__choice">
                                <label htmlFor="OPT_IN">
                                  <input
                                    type="checkbox"
                                    className="input_replaced"
                                    value="1"
                                    id="OPT_IN"
                                    name="OPT_IN"
                                    required
                                  />
                                  <span className="checkbox checkbox_tick_positive"></span>
                                  <span>
                                    <p>
                                      Ich möchte den beSonder Newsletter erhalten und akzeptiere die <Link to="/page/agb">Allgemeinen Geschäftsbedingungen</Link>. *
                                    </p>
                                  </span>{' '}
                                  <span
                                    data-required="*"
                                    className="entry__label entry__label_optin sib_data_req"
                                  ></span>
                                </label>
                              </div>
                            </div>
                            <label htmlFor="OPT_IN" className="entry__error entry__error--primary"></label>
                            <label htmlFor="OPT_IN" className="entry__specification">
                              Du kannst den Newsletter jederzeit über einen Link in unserem Newsletter abbestellen.
                            </label>
                          </div>
                        </div>
                      </div>
                                          
                      <div>
                        <div class="sib-captcha sib-form-block">
                          <div class="form__entry entry_block">
                            <div class="form__label-row">
                              <div class="g-recaptcha sib-visible-recaptcha" id="sib-captcha" data-sitekey="6Ld2T_YUAAAAAAJG1l1K2U2JtBeCw1k4abA2kMHV" data-callback="handleCaptchaResponse"></div>
                            </div>
                            <label class="entry__error entry__error--primary">
                            </label>
                          </div>
                        </div>
                      </div>                    

                      <div>
                        <div className="sib-form-block">
                          <button
                            className="sib-form-block__button sib-form-block__button-with-loader btn btn-lg btn-besonder-red"
                            form="sib-form"
                            type="submit"
                          >
                            <svg
                              className="clickable__icon progress-indicator__icon sib-hide-loader-icon"
                              viewBox="0 0 512 512"
                            >
                              <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                            </svg>
                            Anmelden
                          </button>
                        </div>
                      </div>

                      <input
                        type="text"
                        name="email_address_check"
                        defaultValue=""
                        className="input--hidden"
                      />
                      <input type="hidden" name="locale" value="de" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Newsletter