import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import { Link } from 'prismic-reactjs'

class SimpleMap extends Component {
  static defaultProps = {
    zoom: 15,
  }

  renderMarker = (map, maps, lat, lng, link) => {
    const marker = new maps.Marker({
      map: map,
      position: new maps.LatLng(lat, lng),
    })

    let text = ''
    if (link) {
      text = `<a href=${Link.url(link)}>In Google Maps ansehen</a>`
    } else {
      text = `<a href="https://www.google.com/maps/dir/${lat},${lng}/@${lat},${lng}">In Google Maps ansehen</a>`
    }

    const infowindow = new maps.InfoWindow({
      content: text,
    })
    infowindow.open(map, marker)

    marker.addListener('click', function() {
      infowindow.open(map, marker)
    })
  }

  render() {
    const { lat, lng, link } = this.props
    const center = { lat, lng }

    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyBi2Rhl9OeHo3TX-Q7qdTX-TfdERHlIPcU',
          }}
          defaultCenter={center}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) =>
            this.renderMarker(map, maps, lat, lng, link)
          }
        ></GoogleMapReact>
      </div>
    )
  }
}

export default SimpleMap
