import React from 'react'
import { Link } from "gatsby"
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'

import './shop.scss'

const Image = styled.div`
  display: block;
  z-index: -1;
  background-image: url("${props => props.url}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -o-background-size: contain;
  -moz-background-size: contain;
  -webkit-background-size: contain;
  `

const Produkte = ({ produkte }) => {
  return produkte.fields.map((field) => (
      <div key={field.produkt._meta.uid} className="produkt-preview-container col-12 col-md-4 col-lg-3 p-2 text-center">
        <Link to={`/produkt/${field.produkt._meta.uid}`} className="produkt-link">
          <Image url={field.produkt.bild.url} className="produkt-preview mb-3" />
            <h5>{RichText.asText(field.produkt.produkt_titel)}</h5>
            <strong>{RichText.asText(field.produkt.preis)} €</strong>
        </Link>
      </div>
    ))
}

const Shop = ({ content }) => {
  return (
    <div className="container-grey pb-5">
      <div className="container pr-3 pl-3">
        <div className="row justify-content-center">
          <Produkte produkte={content} />
        </div>
      </div>
    </div>
    
  )
}

export default Shop
