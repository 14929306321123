import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Slider from 'react-slick'
import { RichText } from 'prismic-reactjs'
import { graphql } from 'gatsby'
import { siteMetadata } from '../../gatsby-config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

import { linkResolver } from '../utils/linkResolver'

import Layout from '../components/layouts'
import Meta from '../components/meta'

import './produkt.scss'

export const query = graphql`
  query ProdukteQuery($uid: String) {
    prismic {
      allProduktes(uid: $uid) {
        edges {
          node {
            _meta {
              uid
              id
            }
            produkt_id
            beschreibung_kurz
            beschreibung_lang
            detail_bilder {
              detail_bild 
            }
            naehrwerte {
              naehrwert_bezeichnung
              nahrwert_wert
            }
            produkt_titel
            preis
            produkt_typ
            gewicht_in_gramm
            tShirt_sizes {
              size
            }            
          }
        }
      }
    }
  }
`

const Image = styled.div`
overflow: hidden;
padding-top: 56.25%;
height: 0;
background: url("${props => props.url}") no-repeat center;
background-size: contain;
`

const ImageDots = styled.div`
width: 100%;
height: 100%;
background: url("${props => props.url}") no-repeat center;
background-size: contain;
`

const Button = ({ doc, toggleClass }) => {  
  if (doc.node.produkt_typ === 'T-Shirt') {
    let shirtSizes = ''
    doc.node.tShirt_sizes.map((size) => {
      shirtSizes += RichText.asText(size.size)
      shirtSizes += '|' 
      return null
    })

    shirtSizes = shirtSizes.slice(0, -1)

    return (
      <button
        type="button"
        className="snipcart-add-item btn btn-lg btn-besonder-red"
        data-item-id={RichText.asText(doc.node.produkt_id)}
        data-item-price={RichText.asText(doc.node.preis).replace(
          ',',
          '.'
        )}
        data-item-custom1-name="Größe"
        data-item-custom1-options={shirtSizes}
        data-item-weight={doc.node.gewicht_in_gramm}
        data-item-name={RichText.asText(doc.node.produkt_titel)}
        data-item-image={
          doc.node.detail_bilder[0].detail_bild.url
        }
        data-item-url={
          `/produkt/${doc.node._meta.uid}`
        }
        data-item-taxes="mwst-20"
        data-item-has-taxes-included="true"        
      >                     
        In den Warenkorb
      </button>
    )
  }

  if (doc.node.produkt_typ === 'Buch') {
    return (
      <button
        type="button"
        className="snipcart-add-item btn btn-lg btn-besonder-red"
        data-item-id={RichText.asText(doc.node.produkt_id)}
        data-item-price={RichText.asText(doc.node.preis).replace(
          ',',
          '.'
        )}
        data-item-weight={doc.node.gewicht_in_gramm}
        data-item-name={RichText.asText(doc.node.produkt_titel)}
        data-item-image={
          doc.node.detail_bilder[0].detail_bild.url
        }
        data-item-url={
          `/produkt/${doc.node._meta.uid}`
        }
        data-item-taxes="mwst-10"
        data-item-has-taxes-included="true"
      >                     
        In den Warenkorb
      </button>
    )
  }

  if (doc.node.produkt_typ === 'Gutschein' || 
      doc.node.produkt_typ === 'Getraenk' || 
      doc.node.produkt_typ === 'Package') {
    return (
      <button
        type="button"
        className="snipcart-add-item btn btn-lg btn-besonder-red"
        data-item-id={RichText.asText(doc.node.produkt_id)}
        data-item-price={RichText.asText(doc.node.preis).replace(
          ',',
          '.'
        )}
        data-item-weight={doc.node.gewicht_in_gramm}
        data-item-name={RichText.asText(doc.node.produkt_titel)}
        data-item-image={
          doc.node.detail_bilder[0].detail_bild.url
        }
        data-item-url={
          `/produkt/${doc.node._meta.uid}`
        }
        data-item-taxes="mwst-20"
        data-item-has-taxes-included="true"
      >                     
        In den Warenkorb
      </button>
    )
  }
}

const Produkt = props => {
  const { location } = props

  const [modal, setModal] = useState(false)

  const doc = props.data.prismic.allProduktes.edges.slice(0, 1).pop()
  if (!doc) return null

  if (!doc.node.preis || !doc.node.detail_bilder[0].detail_bild) {
    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Fehler" />
        <div className="container-fluid container-grey p-5 text-center"><h3>Produkt kann nicht angezeigt werden, weil es keinen Preis oder Bilder hat</h3></div>
      </Layout>
    )
  }
  
  let titel
  if (doc.node.produkt_titel) titel = RichText.asText(doc.node.produkt_titel).split('(')[0]

  let beschreibungKurz
  if (doc.node.beschreibung_kurz) beschreibungKurz = RichText.render(doc.node.beschreibung_kurz, linkResolver)

  let beschreibungLang
  if (doc.node.beschreibung_lang) beschreibungLang = RichText.render(doc.node.beschreibung_lang, linkResolver)

  const toggleClass = () => {
    setModal(!modal)
  }

  let detail_bilder = []
  let detail_bilder_dots = []
  doc.node.detail_bilder.map((value, index) => {
    detail_bilder.push(<Image url={value.detail_bild.url} className="produkt-foto"></Image>)
    detail_bilder_dots.push(<ImageDots url={value.detail_bild.url}></ImageDots>)
  })


  const numberPhotos = detail_bilder.length
  const showDots = numberPhotos > 1 ? true : false

  const settings = {
    customPaging: function(i) {
      return <a>{detail_bilder_dots[i]}</a>
    },
    dots: showDots,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    dotsClass: 'produkt-slick-dots',
  }

  let dataIndex = -1
  const Slide = () => {
    dataIndex += 1
    return (
      <div data-index={dataIndex} key={dataIndex}>
        {detail_bilder[dataIndex]}
      </div>
    )
  }

  const ButtonGetraenk = () => (
    <button
      type="button"
      className="btn btn-lg btn-besonder-red"
      onClick={toggleClass}
    >
      In den Warenkorb
    </button>
  )
  
  const Naehrwerte = () => (
    <div className="col-md-4 pt-3 produkt-naehrwert">
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Nährwertangaben</th>
            <th scope="col">je 100 ml</th>
          </tr>
        </thead>
        <tbody>
          {doc.node.naehrwerte.map(naehrwert => {
            return (
              <tr key={RichText.asText(naehrwert.naehrwert_bezeichnung)}>
                <td>{RichText.asText(naehrwert.naehrwert_bezeichnung)}</td>
                <td>{RichText.asText(naehrwert.nahrwert_wert)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )

  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title={titel} />
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />       
      </Helmet>

      <div className="container-grey">
        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-12 text-center">
              <h3>
                {RichText.asText(doc.node.produkt_titel)}
              </h3>
            </div>
          </div>
          <div className="row justify-content-center pt-5">
            <div className="col-12 col-lg-6">
              <Slider {...settings}>{detail_bilder.map(e => Slide())}</Slider>
            </div>
            <div className="col-12 col-lg-4 pb-5">
              <div className="produkt-detail-rechter-text">
                {beschreibungKurz}
              </div>
              <div className="row align-items-center justify-items-center">
                <div className="col-12 col-lg-4 produkt-preis pt-3 pb-3 text-center">
                  {RichText.asText(doc.node.preis).replace(
                    ',',
                    '.'
                  )} €
                </div>
                <div className="col-12 col-lg-6 pt-3 pb-3 text-center">
                  <Button doc={doc} />
                </div>
              </div>
            </div>
            <div
              className={
                doc.node.produkt_typ === 'Getraenk'
                  ? 'col-12 col-lg-6 pt-3 pb-3 pr-5'
                  : 'col-12 col-lg-10 pb-3 pt-3'
              }
            >
              {beschreibungLang}
            </div>
            {doc.node.produkt_typ === 'Getraenk' ? <Naehrwerte /> : ''}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Produkt;