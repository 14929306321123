import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../../utils/linkResolver'
import htmlSerializer from '../../../utils/htmlSerializer'

const Text = ({ text, color, sizeDesktop, sizeMobile, display, specialClass, textCenter }) => {
  let newClass = ''
  if (specialClass) newClass = RichText.asText(specialClass)
  if (color === 'red') newClass += ' text-light'
  if (textCenter) newClass += ' text-center'

  return (
    <div className={`row p-2 justify-content-center align-items-center ${display} ${newClass}`} >
      <div className={`col-${sizeMobile} col-lg-${sizeDesktop} w-100`}>
        { RichText.render(text, linkResolver, htmlSerializer) }
      </div>
    </div>
  )
}

export default Text;