import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import CookieConsent from "react-cookie-consent";

import Navi from '../navi/' 
import Footer from '../footer'
import './index.scss'

const Layout = (props) => {
  // Define the meta title and description
  // Load the Prismic edit button
  if(typeof window !== 'undefined' && window.prismic) {
    window.prismic.setupEditButton()
  }
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]')
  }
  
	return(
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        />
        <link href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900" rel="stylesheet" type="text/css"></link>
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700;800&display=swap" rel="stylesheet"></link>
        <link rel="preconnect" href="https://app.snipcart.com"></link>
        <link rel="preconnect" href="https://cdn.snipcart.com"></link>
        <link rel="stylesheet" href="https://cdn.snipcart.com/themes/v3.0.25/default/snipcart.css" />
      </Helmet>
      <Navi {...props} />
      <main>
        {props.children}
      </main>
      <Footer {...props} />

      <CookieConsent
        enableDeclineButton
        flipButtons
        location="bottom"
        buttonText="Zustimmen"
        declineButtonText="Ablehnen"
        cookieName="gatsby-gdpr-google-analytics"
        style={{ color: "#000", background: "#fff" }}
        buttonClasses="btn btn-lg consent-button"
        declineButtonClasses="btn btn-lg consent-decline"
        buttonWrapperClasses="CookieConsent-Wrapper"
        expires={150}
      >
        <div className="w-100 text-center">
          Wir lieben Cookies, vor allem jene mit Ingwer Geschmack. 
          Daher verwenden wir auch auf dieser Website Cookies, um ein bestmögliches Web-Erlebnis zu ermöglichen. 
          Indem Du auf der Seite weitersurfst, stimmst Du der Cookie-Nutzung zu.{' '}
          <a href="www.besonder.at/page/datenschutz">
            Weitere Informationen findest du in unserer Datenschutzerklärung.
          </a>
        </div>
      </CookieConsent>
    </Fragment>
	)
}

export default Layout