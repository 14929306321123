import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'

const query = graphql`
query FooterQuery {
  prismic {
    allFooters {
      edges {
        node {
          footer_spalte_2 {
            footer_item {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  type
                }
                titel
              }
            }
          }
          footer_spalte_1 {
            footer_item {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  type
                }
                titel
              }
            }
          }
          footer_spalte_3 {
            footer_item {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  type
                }
                titel
              }
            }
          }          
        }
      }
    }
  }
}
`

export default props => (
  <StaticQuery
    query={query}
    render={ data => <Footer data={ data } { ...props }/> }
  />
)

const Footer = (props) => {
  const { location, data } = props
    
  const doc = data.prismic.allFooters.edges.slice(0, 1).pop();

  if (!doc) return null

  return (
    <div className="footer">
      <div className="container">
        <div className="row m-3 justify-content-center align-items-center">
          <div className="col-md-4 text-center">
            {doc.node.footer_spalte_1.map((data, index) => {
              if (data.footer_item) {
                return (
                  <div key={index} className={location.pathname === `/page/${data.footer_item._meta.uid}/` ? 'nav-item active p-1' : 'nav-item'}>
                    <Link to={linkResolver(data.footer_item._meta)}>
                      {RichText.asText(data.footer_item.titel)}
                    </Link>
                  </div>
                )
              }
              })
            }
          </div>
          <div className="col-md-4 text-center">
            {doc.node.footer_spalte_2.map((data, index) => {
              if (data.footer_item) {
                return (
                  <div key={index} className={location.pathname === `/page/${data.footer_item._meta.uid}/` ? 'nav-item active' : 'nav-item'}>
                    <Link to={linkResolver(data.footer_item._meta)}>
                      {RichText.asText(data.footer_item.titel)}
                    </Link>
                  </div>
                )
              }
              })
            }
          </div>
          <div className="col-md-4 text-center">
            {doc.node.footer_spalte_3.map((data, index) => {
              if (data.footer_item) {
                return (
                  <div key={index} className={location.pathname === `/page/${data.footer_item._meta.uid}/` ? 'nav-item active' : 'nav-item'}>
                    <Link to={linkResolver(data.footer_item._meta)}>
                      {RichText.asText(data.footer_item.titel)}
                    </Link>
                  </div>
                )
              }
              })
            }
         </div>          
        </div>
      </div>
    </div>
  )
}