import React from 'react'
import { graphql } from 'gatsby'
import { siteMetadata } from '../../gatsby-config'
import { RichText } from 'prismic-reactjs'

import Layout from '../components/layouts'
import Meta from '../components/meta'
import HomeVideo from '../components/content/home-video/home-video'
import YoutubeVideo from '../components/content/youtube/youtube'
import Kontakt from '../components/content/kontakt/kontakt'
import Buch from '../components/content/buch/buch'
import InspirationenVorschlag from '../components/content/inspirationen-vorschlag/inspirationen-vorschlag'
import Newsletter from '../components/content/newsletter/newsletter'
import Shop from '../components/content/shop/shop'
import Entdeckungen from '../components/content/entdeckungen/entdeckungen'
import ErlebnisseTop from '../components/content/erlebnisse-top/erlebnisse-top'
import OneContentBlock from '../components/content/oneContentBlock'
import TwoContentBlock from '../components/content/twoContentBlock'
import FourContentBlock from '../components/content/fourContentBlock'

import './page.scss'

export const query = graphql`
query PageQuery($uid: String) {
  prismic {
    allPages(uid: $uid) {
      edges {
        node {
          body {
            ... on PRISMIC_PageBodyYoutubevideo {
              type
              primary {
                video_id
                content_block_farbe
                abstand_rand
                size_mobil
                size_desktop
              }
            }            
            ... on PRISMIC_PageBodyBuch_leseprobe {
              type
              label
            }
            ... on PRISMIC_PageBodyShop {
              type
              label
              fields {
                produkt {
                  ... on PRISMIC_Produkte {
                    _meta {
                      uid
                    }
                    preis
                    bild
                    produkt_titel
                  }
                }
              }
            }
            ... on PRISMIC_PageBodyNewsletter {
              type
              label
            }
            ... on PRISMIC_PageBodyKontaktformular {
              type
              label
            }
            ... on PRISMIC_PageBodyInspirationen_umfrage {
              type
              label
            }
            ... on PRISMIC_PageBodyErlebnissetop {
              type
              label
            }
            ... on PRISMIC_PageBodyEntdeckungen {
              type
              label
              fields {
                entdeckung {
                  ... on PRISMIC_Entdeckungen {
                    vorschau_bild
                    titel
                    _meta {
                      uid
                      type
                    }
                    ort
                    preis
                  }
                }
              }
            }
            ... on PRISMIC_PageBodyOnecontentblock {
              type
              fields {
                anchor
                bild
                button_color
                button_link {
                  ... on PRISMIC_Page {
                    _meta {
                      uid
                      type
                    }
                    _linkType
                  }
                  ... on PRISMIC__ExternalLink {
                    url
                    _linkType
                  }
                  ... on PRISMIC__FileLink {
                    _linkType
                    url
                  }
                  ... on PRISMIC__ImageLink {
                    _linkType
                    url
                  }
                }
                button_text
                size_desktop
                render
                size_mobil
                spezial_klasse
                text
                text_mehr_anzeigen
                titel_slice
              }
              primary {
                content_block_color
                abstand_rand
              }
            }
            ... on PRISMIC_PageBodyTwocontentblock {
              type
              primary {
                content_block_color
                spalte_1_breite_desktop
                spalte_1_breite_mobil
                spalte_2_breite_desktop
                spalte_2_breite_mobil
                abstand_rand
              }
              fields {
                anchor
                bild
                button_color
                button_link {
                  ... on PRISMIC_Page {
                    _meta {
                      uid
                      type
                    }
                    _linkType
                  }
                  ... on PRISMIC__ExternalLink {
                    url
                    _linkType
                  }
                  ... on PRISMIC__FileLink {
                    _linkType
                    url
                  }
                  ... on PRISMIC__ImageLink {
                    _linkType
                    url
                  }
                }
                button_text
                render
                size_desktop
                size_mobil
                spalte_1_oder_2
                spezial_klasse
                text
                text_mehr_anzeigen
                titel_slice
              }
            }
            ... on PRISMIC_PageBodyFourcontentblock {
              type
              primary {
                content_block_color
                spalte_1_breite_desktop
                spalte_1_breite_mobil
                spalte_2_breite_desktop
                spalte_2_breite_mobil
                spalte_3_breite_desktop
                spalte_3_breite_mobil
                spalte_4_breite_desktop
                spalte_4_breite_mobil
                abstand_rand
              }
              fields {
                anchor
                titel_slice
                text_mehr_anzeigen
                text
                spezial_klasse
                spalte_1_oder_2_oder_3_oder_4
                size_desktop
                size_mobil
                render
                button_text
                button_link {
                  ... on PRISMIC_Page {
                    _meta {
                      uid
                      type
                    }
                    _linkType
                  }
                  ... on PRISMIC__ExternalLink {
                    url
                    _linkType
                  }
                  ... on PRISMIC__FileLink {
                    _linkType
                    url
                  }
                  ... on PRISMIC__ImageLink {
                    _linkType
                    url
                  }
                }
                button_color
                bild
              }
            }
            ... on PRISMIC_PageBodyHomevideo {
              primary {
                video {
                  ... on PRISMIC__FileLink {
                    url
                  }
                }
                video_preaload_image {
                  ... on PRISMIC__FileLink {
                    url
                  }
                }
              }
              type
            }
          }
          titel
          _meta {
            uid
            id
          }
        }
      }
    }
  }
}

`

const Page = (props) => {
  const { location } = props

  const doc = props.data.prismic.allPages.edges.slice(0,1).pop();
  if (!doc) return null;

  let titel = ''
  if(doc.node.titel) titel = RichText.asText(doc.node.titel)
  
  if (!doc.node.body) {
      return (
        <Layout location={location}>
          <Meta site={siteMetadata} title={titel} />
          <div className="container-fluid container-grey p-5 text-center"><h3>Kein Inhalt</h3></div>
        </Layout>
      )
  }

  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title={titel} />
      {doc.node.body.map((slice, index) => {
        switch (slice.type) {
          case 'homevideo': return <HomeVideo key={`${index}`} content={slice} />
          case 'youtubevideo': return <YoutubeVideo key={`${index}`} content={slice} />
          case 'OneContentBlock': return <OneContentBlock key={`${index}`} uid={doc.node._meta.uid} content={slice} />
          case 'TwoContentBlock': return <TwoContentBlock key={`${index}`} uid={doc.node._meta.uid} content={slice} />
          case 'FourContentBlock': return <FourContentBlock key={`${index}`} uid={doc.node._meta.uid} content={slice} />
          case 'ErlebnisseTop': return <ErlebnisseTop key={`${index}`} content={slice} />
          case 'shop': return <Shop key={`${index}`} content={slice} />
          case 'buch_leseprobe': return <Buch key={`${index}`} content={slice} />
          case 'newsletter': return <Newsletter key={`${index}`} content={slice} />
          case 'kontaktformular': return <Kontakt key={`${index}`} content={slice} />
          case 'entdeckungen': return <Entdeckungen key={`${index}`} content={slice} />
          case 'inspirationen_umfrage': return <InspirationenVorschlag key={`${index}`} content={slice} />
          default: return <div key={`${index}`} className="container-fluid container-grey p-5 text-center"><h3>Leeres Element</h3></div>
        }
      })}
    </Layout>
  )
}

export default Page;