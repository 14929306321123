import React from 'react'
import uniqueId from 'lodash/uniqueId'

import Anchor from './elements/anchor'
import Button from './elements/button'
import ButtonMore from './elements/buttonMore'
import ButtonAnchor from './elements/buttonAnchor'
import Text from './elements/text'
import Image from './elements/image'

const OneContentBlock = (props) => {
  const { uid } = props;
  const { primary, fields } = props.content;
  const { content_block_color: color, abstand_rand: padding } = primary;

  return (
    <div className={`container-fluid container-${color} p-${padding}`}>
      {fields.map((field, index) => {
        switch (field.render) {
          case 'Titel': return (
              <Text
                key={uniqueId(uid + '-')}
                text={field.titel_slice}
                color={color}
                sizeDesktop={field.size_desktop}
                sizeMobile={field.size_mobil}
                textCenter={true}
                specialClass={field.spezial_klasse} />
            )
          case 'Text': return (
              <Text
                key={uniqueId(uid + '-')}
                text={field.text}
                color={color}
                sizeDesktop={field.size_desktop}
                sizeMobile={field.size_mobil}
                textCenter={true}
                specialClass={field.spezial_klasse} />
            )
            case 'Bild': return (
              <Image
                key={uniqueId(uid + '-')}
                image={field.bild}
                sizeDesktop={field.size_desktop}
                sizeMobile={field.size_mobil}
                specialClass={field.spezial_klasse} />
            )
            case 'Button Link': return (
            <Button
              key={uniqueId(uid + '-')}
              buttonLink={field.button_link}
              buttonColor={field.button_color}
              buttonText={field.button_text}
              sizeDesktop={field.size_desktop}
              sizeMobile={field.size_mobil}
              textCenter={true}
              specialClass={field.spezial_klasse} />
          )
          case 'Button Mehr Anzeigen': return (
            <ButtonMore
              key={uniqueId(uid + '-')}
              buttonColor={field.button_color}
              buttonText={field.button_text}
              moreText={field.text_mehr_anzeigen}
              sizeDesktop={field.size_desktop}
              sizeMobile={field.size_mobil}      
              textCenter={true}
              specialClass={field.spezial_klasse} />
          )
          case 'Button Anchor': return (
            <ButtonAnchor
              key={uniqueId(uid + '-')}
              buttonColor={field.button_color}
              buttonText={field.button_text}
              anchor={field.anchor}
              sizeDesktop={field.size_desktop}
              sizeMobile={field.size_mobil}                
              textCenter={true}
              specialClass={field.spezial_klasse} />
          )
          case 'Anchor': return (
            <Anchor
              key={uniqueId(uid + '-')}
              anchor={field.anchor} />
          )    
            default: return null
          }
        })}
    </div>
  ) 
}

export default OneContentBlock;