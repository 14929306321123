import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import isemail from 'isemail'
import ReCAPTCHA from "react-google-recaptcha"

import './inspirationen-vorschlag.scss'

const ThankYou = () => (
  <div className="pt-3">
    Vielen Dank für deinen Vorschlag!
  </div>
)

const RecaptchaMessage = () => (
  <div className="invalid-feedback p-3">
    Bitte das reCAPTCHA Überprüfung durchführen! 
  </div>
)
const InspirationenVorschlag = () => {
  const firstRender = useRef(true)
  const reCaptchaRef = useRef(null)

  const [message, setMessage] = useState('');

  const [submit, setSubmit] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [verified, setVerified] = useState(false)
  const [showRecaptchaMessage, setShowRecaptchaMessage] = useState(false)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

  },[showRecaptchaMessage]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault()

    if (!verified) {
      setShowRecaptchaMessage(true)
      return
    } 

    const content = {
      messageContent: message,
    }

    try {
      axios({
        method: 'post',
        url: `/.netlify/functions/inspirationen-form/`,
        data: content,
      })
    } catch (e) {
      console.log(e)
    }

    setSubmitted(true)
  }

  const handleCaptchaChange = value => {
    if (value !== null) {
      setVerified(true)
      setShowRecaptchaMessage(false)
    }
  }


  return (
      <div className="container-yellow">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 text-center">
              {submitted ? (
                <ThankYou />
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <textarea
                            className="w-100"
                            type="text"
                            name="message"
                            rows="7"
                            required
                            value = { message }
                            onChange={ e => setMessage(e.target.value) }
                        />
                      </div>
                      <div className="col-12 text-center">
                        <ReCAPTCHA
                          ref={reCaptchaRef}
                          sitekey="6Ld2T_YUAAAAAAJG1l1K2U2JtBeCw1k4abA2kMHV"
                          onChange={handleCaptchaChange}
                          style={{ display: "inline-block" }}
                        />
                      </div>
                      {showRecaptchaMessage ? <RecaptchaMessage /> : ''}
                      <div className="col-12">
                        <button className="btn btn-lg btn-besonder-red">Absenden</button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    )
}

export default InspirationenVorschlag
