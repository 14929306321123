
import React from 'react'
import { Link } from "gatsby"
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../../utils/linkResolver'

const Button = ({ buttonLink, buttonColor, buttonText, sizeDesktop, sizeMobile, display, textCenter, specialClass }) => {
  if (!buttonLink || !buttonText) return null;
  
  let newClass = ''
  if (specialClass) newClass = RichText.asText(specialClass)
  if (textCenter) newClass += ' text-center'

  let button
  if (buttonLink._linkType === 'Link.document') {
    button = <Link to={linkResolver(buttonLink._meta)} className={`btn btn-lg btn-besonder-${buttonColor}`}>
              {RichText.asText(buttonText)}
            </Link>
  } else {
    button = <a className={`btn btn-lg btn-besonder-${buttonColor}`} href={buttonLink.url} >{RichText.asText(buttonText)}</a>
  }

  return (
    <div className={`row p-2 justify-content-center align-items-center ${display} ${newClass}`}>
      <div className={`col-${sizeMobile} col-lg-${sizeDesktop}`}>
         {button}
      </div>
    </div>
  );       
}

export default Button;