import React from 'react'
import { Player, ControlBar, BigPlayButton } from 'video-react'

import './home-video.scss'

const HomeVideo = ({ content }) => {
  const { video, video_preaload_image: preload } = content.primary

  return (
    <div className="container-fluid player-container d-none d-lg-block">
      <Player
        poster={preload.url}
        loop
        autoPlay={true}
        muted={true}
        playsInline={true}
        src={video.url}
      >
        <BigPlayButton disabled />
        <ControlBar disabled />
      </Player>
    </div>
  )
}

export default HomeVideo