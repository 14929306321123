import React from 'react'
import { Link } from "gatsby"
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import './entdeckungen.scss'

const Image = styled.div`
background: url("${props => props.url}") no-repeat center;
background-size: cover;
`

const Entdeckung = ({ field }) => {
  const { entdeckung } = field;


  const Standort = () => (
    <>
      <FontAwesomeIcon
        icon={faMapMarkerAlt}
        className="fa-lg mr-2"
      /> 
      <div>{RichText.asText(entdeckung.ort)}</div>
    </>
  )

  let disabledLink = ''
  if(entdeckung._meta.uid === 'entdeckung-weitere-folgen') disabledLink = "disabled-link"

  return (
    <div className="entdeckung-preview-container col-lg-4 col-md-6 p-0">
      <Link to={`/entdeckung/${entdeckung._meta.uid}`} className={`entdeckungen-link ${disabledLink}`}>
        <Image url={entdeckung.vorschau_bild.url} className="entdeckung-preview">
          <div className="d-flex align-items-center flex-column entdeckung-preview text-light">
            <div className="p-4 text-center">
              {RichText.asText(entdeckung.titel)}
            </div>
            <div className="mt-auto w-75 pb-4">
              <div className="row mt-3">
                {/* when price is available: <div className="col-8 align-self-center"> */ }
                <div className="col-12 text-center">
                  {!disabledLink ? <Standort /> : ''}
                </div>
                <div className="col-4 align-self-end text-right entdeckung-preis">
                  {/* {entdeckung.preis} € */}
                </div>
              </div>
            </div>
          </div>
        </Image>
      </Link>
    </div>
  )
}


const Entdeckungen = ({ content }) => {
  return (
    <div className="container-fluid container-grey">
      <div className="row">
        {content.fields.map((field) => <Entdeckung key={field.entdeckung._meta.uid} field={field} />)}
      </div>
    </div>
  )
}

export default Entdeckungen