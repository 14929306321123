import React from 'react'
import { Link } from "gatsby"

import './erlebnisse-top.scss'

const ErlebnisseTop = () => {
  return (
    <div className="container-fluid text-light">
      <div className="row align-items-bottom">
        <div className="col-md-6 p-0 text-center entdeckungen-top">
          <div className="d-flex align-items-end h-100 p-5">
            <div className="row">
              <div className="col-12 pb-3">
                <p className="lead text-light">
                  Wann hast du das letzte Mal etwas zum ersten Mal probiert?
                  Entdecke spannende Personen direkt vor deiner Haustür,
                  erhalte exklusive Einblicke, packe selbst an und nimm dir
                  Neues für dein eigenes Leben mit!
                </p>
              </div>
              <div className="col-12">
                <Link
                  to="page/erlebnisse/#entdeckungen"
                  className="btn btn-besonder-red btn-lg"
                >
                  Entdeckungen
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 p-0 text-center inspirationen-top">
          <div className="d-flex align-items-end h-100 p-5">
            <div className="row">
              <div className="col-12 pb-3">
                <p className="lead text-light">
                  Wie können die großen Herausforderungen unserer Zeit
                  bewältigt werden? Am besten wissen das oft Personen „von
                  nebenan“, die täglich mit diesen konfrontiert sind. Nimm
                  teil an unseren Inspirationsformaten und rede mit!
                </p>
              </div>
              <div className="col-12">
                <Link
                  to="page/erlebnisse/#inspirationen"
                  className="btn btn-besonder-yellow btn-lg"
                >
                  Inspirationen
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErlebnisseTop