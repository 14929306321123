
import React from 'react';
import { RichText } from 'prismic-reactjs'

const Anchor = ({ anchor }) => {
  
  if (!anchor) return null;

  return (
    <div className="anchor" id={RichText.asText(anchor)}></div>
  );       
}

export default Anchor;