import React, { useState, useRef } from 'react'
import Slider from 'react-slick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet'

import './buch.scss'

const Buch = () => {

  const [modal, setModal] = useState(false)
  const [slideIndex, setSlideIndex] = useState(0)

  const sliderSmall = useRef()
  const sliderBig = useRef()

  const toggleClass = () => {
    sliderSmall.current.slickGoTo(slideIndex)
    sliderBig.current.slickGoTo(slideIndex)
    setModal(!modal)
  }

  let leseprobe = []

  for (let i = 0; i < 12; i++) {
    leseprobe.push(
      <div><img alt="" className="img-fluid buch-fotos" src={'/leseprobe/' + i + '.jpg'} /></div>
    )
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (current, next) => setSlideIndex(next),
  }

  const settingsBig = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (current, next) => setSlideIndex(next),
  }

  let dataIndex = -1
  const Slide = () => {
    dataIndex += 1
    return (
      <div
        data-index={dataIndex}
        key={dataIndex}
        className="buch-klein"
        onClick={toggleClass}
      >
        {leseprobe[dataIndex]}
      </div>
    )
  }

  let dataIndexBig = -1
  const SlideBig = () => {
    dataIndexBig += 1
    return (
      <div data-index={dataIndexBig} key={dataIndexBig}>
        {leseprobe[dataIndexBig]}
      </div>
    )
  }

  return (
    <div className="container-grey">
      <div id="modal" className={modal ? 'show-modal' : 'hide-modal'}>
        <div className="container-fluid">
          <div className="row sticky-top text-right m-n4">
            <div className="col-12">
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="fa-lg info-close"
                onClick={toggleClass}
              />
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-10 col-md-6">
              <Slider
                ref={slider => (sliderBig.current = slider)}
                {...settingsBig}
              >
                {leseprobe.map(e => SlideBig())}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row p-5 justify-content-center align-items-center text-center">
          <div className="col-12 text-center">
            <h3>Blick ins Buch</h3>
          </div>
          <Helmet>
            <link
              rel="stylesheet"
              type="text/css"
              charSet="UTF-8"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />       
          </Helmet>
          <div className="col-7 col-md-4 pt-3">
            <Slider
              ref={slider => (sliderSmall.current = slider)}
              {...settings}
            >
              {leseprobe.map(e => Slide())}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Buch