
import React, { useState } from 'react';
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../../utils/linkResolver'

const ButtonMore = ({ buttonColor, buttonText, moreText, sizeDesktop, sizeMobile, textCenter, specialClass }) => {
  const [showMore, setShowMore] = useState(false);
  
  if (!buttonText || !moreText) return null;

  let newClass = ''
  if (specialClass) newClass = RichText.asText(specialClass)
  if (textCenter) newClass += ' text-center'

  let classShowMore = ''
  if (!showMore) {
    classShowMore = ' d-none'
  } else {
    classShowMore = ''
  }

  return (
    <div>
      <div className={`row justify-content-center align-items-center text-center ${newClass}`}>
        <div className={`col-${sizeMobile} col-lg-${sizeDesktop}`}>
          <button className={`btn btn-besonder-${buttonColor} btn-lg w-100`}
            onClick={() => setShowMore(!showMore)}
            >
            {RichText.asText(buttonText)}
          </button>
        </div>
      </div>
      <div className={`row pt-3 justify-content-center align-items-center text-center ${newClass} ${classShowMore}`}>
        <div className={`col-${sizeMobile} col-lg-${sizeDesktop}`}>
          {RichText.render(moreText, linkResolver)}
        </div>
      </div>
    </div>
  );       
}

export default ButtonMore;