import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import isemail from 'isemail'
import ReCAPTCHA from "react-google-recaptcha"

import './kontakt.scss'

const validate = (email) => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

    return expression.test(String(email).toLowerCase())
}

const ThankYou = () => (
  <div className="pt-3">
    Vielen Dank für deine Nachricht, wir bearbeiten diese schnellstmöglich!
  </div>
)

const InvalidEmail = () => (
  <div className="invalid-feedback">
    Bitte eine korrekte E-Mail Adresse angeben.
  </div>
)

const RecaptchaMessage = () => (
  <div className="invalid-feedback p-3">
    Bitte das reCAPTCHA Überprüfung durchführen! 
  </div>
)
const Kontakt = () => {
  const firstRender = useRef(true)
  const reCaptchaRef = useRef(null)

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [emailIsValid, setEmailIsValid] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [verified, setVerified] = useState(false)
  const [showRecaptchaMessage, setShowRecaptchaMessage] = useState(false)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    setEmailIsValid(validate(email))

  },[email, showRecaptchaMessage]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault()

    setSubmit(true)    

    if (!emailIsValid) return

    if (!verified) {
      setShowRecaptchaMessage(true)
      return
    } 

    const content = {
      senderName: name,
      senderEmail: email,
      messageContent: message,
    }

    try {
      axios({
        method: 'post',
        url: `/.netlify/functions/contact-form/`,
        data: content,
      })
    } catch (e) {
      console.log(e)
    }

    setSubmitted(true)
  }

  const handleCaptchaChange = value => {
    if (value !== null) {
      setVerified(true)
      setShowRecaptchaMessage(false)
    }
  }


  return (
      <div className="container-grey">
        <div className="container kontakt-container pb-5">
          <div className="row align-items-center">
            <div className="col-md-12 text-center">
              <h3 className="font-weight-bold mt-5">Nimm Kontakt mit uns auf!</h3>
              {submitted ? (
                <ThankYou />
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12">Name</div>
                      <div className="col-12 pb-3">
                        <input className="w-100" type="text" name="name" required value={name} onChange={ e => setName(e.target.value) } />
                      </div>
                      <div className="col-12">E-Mail</div>
                      <div className="col-12 pb-3">
                        <input
                            type="text"
                            name="email"
                            className={
                              submit && !emailIsValid
                                ? 'w-100'
                                : 'w-100'
                            }
                          required
                            value={email}
                            onChange={ e => setEmail(e.target.value) }
                        />
                        {submit && !emailIsValid ? <InvalidEmail /> : ''}
                      </div>
                      <div className="col-12">Nachricht</div>
                      <div className="col-12 pb-3">
                        <textarea
                            className="w-100"
                            type="text"
                            name="message"
                            rows="7"
                            required
                            value = { message }
                            onChange={ e => setMessage(e.target.value) }
                        />
                      </div>
                      <div className="col-12 text-center">
                        <ReCAPTCHA
                          ref={reCaptchaRef}
                          sitekey="6Ld2T_YUAAAAAAJG1l1K2U2JtBeCw1k4abA2kMHV"
                          onChange={handleCaptchaChange}
                          style={{ display: "inline-block" }}
                        />
                      </div>
                      {submit && showRecaptchaMessage ? <RecaptchaMessage /> : ''}
                      <div className="col-12">
                        <button className="btn btn-lg btn-besonder-red">Absenden</button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    )
}

export default Kontakt
