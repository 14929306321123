import React, { useState } from 'react'
import YouTube from 'react-youtube';
import { RichText } from 'prismic-reactjs'

import './youtube.scss'

const YoutubeVideo = ({ content }) => {
  const [player, setPlayer] = useState(null);

  const onReady = (event) => {
    // eslint-disable-next-line
    setPlayer(event.target);
  };

  const {
    video_id: videoId,
    abstand_rand: padding,
    size_desktop: sizeDesktop,
    size_mobil: sizeMobil,
    content_block_farbe: color } = content.primary
  
  const opts = {
    host: 'https://www.youtube-nocookie.com',
    }

  return (
    <div className={`container-fluid container-${color} p-3`}>
      <div className="row justify-content-center align-items-center text-center p-3">
        <div className={`col-${sizeMobil} col-lg-${sizeDesktop} p-${padding}`}>
          <div className="videoWrapper">
            <YouTube videoId={RichText.asText(videoId)} opts={opts} onReady={onReady} />
          </div>
        </div>
      </div>
    </div>
  )
}
  
export default YoutubeVideo