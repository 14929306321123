import React from 'react'
import { RichText } from 'prismic-reactjs'

const Image = ({ image, sizeDesktop, sizeMobile, display, specialClass }) => {
  let newClass = ''
  if (specialClass) newClass = RichText.asText(specialClass)

  return (
    <div className={`row p-2 justify-content-center align-items-center text-center ${display} ${newClass}`}>
      <div className={`col-${sizeMobile} p-2 col-lg-${sizeDesktop}`}>
        <img alt="" className={`img-fluid`} src={image.url} />
      </div>
    </div>
  )
}

export default Image;